import { lazy } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { Box } from "UI/Box";
import { Container } from "UI/Container";
import { Footer } from "components/Footer";
import LazyLoadPage from "pages/LazyLoadPage/LazyLoadPage";

import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { basicTheme, theme } from "theme";

const MainPage = lazy(() => import("pages/MainPage"));

const App = () => {
  const location = useLocation();

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ bgcolor: basicTheme.palette.mainPalette.primaryBlack }}>
          <Container
            sx={{
              minHeight: "100vh",
              height: "100%",
              maxWidth: 932,
              mx: "auto",
            }}
          >
            <Routes>
              <Route path="/" element={<Navigate to={`/Home`} />} />
              <Route
                path={`*`}
                element={
                  <Navigate to={`/shared-trips`} state={{ from: location }} />
                }
              />
              <Route
                path={`/shared-trips`}
                element={<LazyLoadPage children={<MainPage />} />}
              />
            </Routes>
            <Footer />
          </Container>
        </Box>
      </ThemeProvider>
    </Box>
  );
};

export default App;
